<template>
  <div
    class="body"
    :style="{
      backgroundImage: 'url(' + data.backgroundCover + ')',
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }"
  >
    <div class="content">
      <div class="left">
        <div class="title">
          {{ data.title }}
        </div>
        <div class="desc">
          {{ data.description }}
        </div>
      </div>
      <div class="right">
        <div class="image">
          <img
            src="https://www.tongyuan.cc/templets/seowhy/img/fa_banner-fr.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      titleInfo: [],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 580px;
  text-align: center;
  color: #f7f8f8;
  background-repeat: no-repeat;
  .content {
    max-width: 1000px;
    width: 92%;
    margin: auto;
    display: block;
  }
  .left {
    width: 38%;
    float: left;
  }
  .right {
    width: 57%;
    float: right;
  }
  .image {
    max-width: 100%;
    padding-top: 110px;
  }
  .title {
    padding-top: 110px;
    font-size: 28px;
    text-align: left;
  }
  .desc {
    width: 92%;
    max-width: 850px;
    line-height: 36px;
    font-size: 20px;
    text-align: left;
    margin: 60px auto 0 auto;
  }
}
</style>
