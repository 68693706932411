<template>
  <div>
    <Title :data="pageInfo"></Title>
    <Block :data="pageInfo.blocks" v-if="pageInfo.blocks"></Block>
  </div>
</template>

<script>
import Title from "./components/title.vue";
import Block from "../block";
export default {
  components: {
    Title,
    Block,
  },
  data() {
    return {
      pageInfo: {},
      userInfo: null,
      blocks: [
        {
          detail: "block0",
          items: [
            {
                title: "方案论证",
                icon: "https://www.tongyuan.cc/uploads/image/category/fa02.png"
            },
            {
                title: "系统仿真",
                icon: "https://www.tongyuan.cc/uploads/image/category/fa03.png"
            },
            {
                title: "虚拟实验",
                icon: "https://www.tongyuan.cc/uploads/image/category/fa023133.png"
            },
            {
                title: "数字孪生",
                icon: "https://www.tongyuan.cc/uploads/image/category/fa01.png"
            }
          ],
          layoutType: {
            id: 0,
            className: "columnsix",
          },
          title: "专题解决方案",
        },
        {
          detail: "block0",
          items: [
            {
                title: "航天领域",
                cover: "https://www.tongyuan.cc/uploads/image/category/fa04.jpg"
            },
            {
                title: "航空领域",
                cover: "https://www.tongyuan.cc/uploads/image/category/fa05.jpg"
            },
            {
                title: "能源领域",
                cover: "https://www.tongyuan.cc/uploads/image/category/fa07.jpg"
            },
            {
                title: "汽车领域",
                cover: "https://www.tongyuan.cc/uploads/image/category/fa06.jpg"
            }
          ],
          title: "行业解决方案",
          layoutType: {
            id: 0,
            className: "columnfour",
          },
        }
      ],
    };
  },
  created() {
    this.userInfo = system.userInfo;
    this.getPage({ router: this.$route.path });
  },
  methods: {
    async getPage(params) {
      const res = await this.$api.getPage(params);
      if (res.code == 200) {
        this.pageInfo = res.data;
        this.pageInfo.blocks = this.blocks;
      }
    },
  },
};
</script>

<style>
</style>
